<template>
  <a-modal
    title="新增企业"
    width="50%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form" :selfUpdate="true">
        <a-row :gutter="24">
          <a-col :md="12" :sm="24">
            <!-- 提现方式 -->
            <a-form-item label="企业名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <!-- 提现方式 -->
            <a-form-item label="联系人姓名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['linkName', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <!-- 提现方式 -->
            <a-form-item label="联系人手机号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['linkMobile', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <!-- 提现方式 -->
            <a-form-item label="企业账号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['account', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <!-- 提现方式 -->
            <a-form-item
              label="企业登陆密码"
              extra="系统随机生成，可手动修改"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              has-feedback
            >
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'pwd',
                  { rules: [{ required: true, message: '请输入！' }], initialValue: createPassword() },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { createPassword } from '@/utils/util'
import { enterpriseAdd } from '@/api/modular/mallLiving/enterprises'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading: false,
      picture: '',
      cos: null,
    }
  },

  methods: {
    createPassword: createPassword,
    // 初始化方法
    add() {
      //下面是正常的初始化
      this.visible = true
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          enterpriseAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增企业成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
